<template>
  <div>
    <v-card flat class="mt-5">
      <v-row no-gutters>
        <v-col
          v-if="$vuetify.breakpoint.name != 'xs'"
          cols="12"
          md="12"
          sm="12"
          lg="12"
          xl="12"
        >
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :items="ShiftItems"
              :disabled="isLoading == true"
              item-text="workshift_name"
              item-value="workshift_id"
              @change="get_daily_late_report()"
              class="mt-5"
              style="max-width: 220px"
            ></v-select>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-5 ml-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  style="max-width: 180px"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_late_report()"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <v-text-field
              label="Search"
              class="mt-5 ml-2"
              v-model="search"
              dense
              style="max-width: 220px"
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-select
              v-model="shiftName"
              label="Select Shift"
              dense
              outlined
              :items="ShiftItems"
              item-text="workshift_name"
              item-value="workshift_id"
              @change="get_daily_late_report()"
              class="mr-2 mt-5"
              style="max-width: 250px"
            ></v-select>
            <v-dialog
              ref="dialog"
              v-model="modal"
              color="#f4813f"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  dense
                  outlined
                  class="mt-5 mr-2"
                  persistent-hint
                  :disabled="isLoading == true"
                  label="Filter by Date"
                  color="#f4813f"
                  append-icon="mdi-calendar"
                  @click:append="date ? getcal() : getcal()"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                :min="minmonth"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="date"
                color="#f4813f"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="#f4813f" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="#f4813f"
                  v-on:click="get_daily_late_report()"
                  @click="$refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-toolbar>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.name == 'xs'" cols="12" md="7">
          <v-toolbar dense elevation="0">
            <v-text-field
              label="Search"
              class="mt-5 mr-2"
              v-model="search"
              dense
              append-icon="mdi-magnify"
            ></v-text-field>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="lateData"
          :items="lateItems"
          dense
          :search="search"
          :height="height"
          :loading="isLoading"
          class="overflow-y-auto"
          :items-per-page="50"
          :fixed-header="fixed"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text"
              >No Absent / swipe data available
            </v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" class="gradient-bg2 white--text">
              No records found!
            </v-alert>
          </template>
          <template v-slot:[`item.user_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.user_name }}</div>
              </template>
              <span class="white--text">{{ item.user_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.location_name`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div class="overflow" v-on="on">{{ item.location_name }}</div>
              </template>
              <span class="white--text">{{ item.location_name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.swipe_time`]="{ item }">
            <span v-text="get_date(item).split(',')[1]"></span>
          </template>
          <template v-slot:[`item.swipe_source`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <div v-on="on" v-if="item.swipe_source == 'WHATSAPP'">
                  <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'FACE'">
                  <v-icon color="#757575" small>mdi-face-recognition</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'CAMERA'">
                  <v-icon color="#757575" small>mdi-camera</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'RESWIPE'">
                  <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'PROXY'">
                  <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'BOX'">
                  <v-icon color="#757575" small
                    >mdi-card-account-details-outline</v-icon
                  >
                </div>
                <div
                  v-on="on"
                  v-else-if="item.swipe_source == 'DATACORRECTION'"
                >
                  <v-icon color="#757575" small>mdi-human-edit</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'BEACON'">
                  <v-icon color="#757575" small>mdi-bluetooth-audio</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'DESKTOP'">
                  <v-icon color="#757575" small> mdi-desktop-classic</v-icon>
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'RFID'">
                  <v-icon color="#757575" small
                    >mdi-card-account-details-outline</v-icon
                  >
                </div>
                <div v-on="on" v-else-if="item.swipe_source == 'SIMPLE'">
                  <v-icon color="#757575" small>mdi-line-scan</v-icon>
                </div>
              </template>
              <span v-if="item.swipe_source == 'WHATSAPP'" class="white--text"
                >Source: Whatsapp</span
              >
              <span v-else-if="item.swipe_source == 'DESKTOP'" class="white--text"
                >Source : Desktop</span
              >
              <span
                v-else-if="item.swipe_source == 'CAMERA'"
                class="white--text"
                >Source: QR</span
              >
              <span v-else-if="item.swipe_source == 'FACE'" class="white--text"
                >Source: FACE</span
              >
              <span
                class="white--text"
                v-else-if="item.swipe_source == 'RESWIPE'"
                >Source: Re-Scan</span
              >
              <span
                v-else-if="item.swipe_source == 'DATACORRECTION'"
                class="white--text"
              >
                Source: Data Correction</span
              >
              <span
                v-else-if="item.swipe_source == 'PROXY'"
                class="white--text"
              >
                Source: PROXY</span
              >
              <span v-else-if="item.swipe_source == 'BOX'" class="white--text">
                Source: BOX</span
              >
              <span
                v-else-if="item.swipe_source == 'BEACON'"
                class="white--text"
              >
                Source: BEACON</span
              >
              <span v-else-if="item.swipe_source == 'RFID'" class="white--text">
                Source : RFID</span
              >
              <span v-else-if="item.swipe_source == 'SIMPLE'" class="white--text">
                Source : Simple</span
              >
            </v-tooltip>
          </template>
          <template v-slot:[`item.late_swipe_duration`]="{ item }">
            <span>{{ callTimeConverter(item.late_swipe_duration) }}</span>
          </template>
          <template v-slot:[`item.cont_num`]="{ item }">
            <span
              v-text="item.user_country_code + '-' + item.user_contact_number"
            ></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <Overlay :overlay="overlay" />
  </div>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { LateArrivalsUsersList } from "@/graphql/queries.js";
import Overlay from "@/components/Overlay.vue";
import { Getshiftwork } from "@/mixins/GetWorkshift.js";

export default {
  components: {
    Overlay,
  },
  mixins: [Getshiftwork],
  data: (vm) => ({
    sortBy: "late_swipe_duration",
    sortDesc: true,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    date: new Date().toISOString().substr(0, 10),
    modal: false,
    height: 0,
    isLoading: false,
    overlay: false,
    search: "",
    timeZone: "",
    lateData: [
      { text: "", value: "swipe_source" },
      { text: "Member Id", value: "employee_id" },
      { text: "Member Name", value: "user_name" },
      { text: "Contact Number", value: "cont_num" },
      { text: "Swiped On", value: "swipe_time" },
      { text: "Late By", value: "late_swipe_duration" },
      { text: "Location Name", value: "location_name" },
    ],
    lateItems: [],
    fixed: true,
    ShiftItems: [],
    shiftName: "",
    minmonth: "",
    selectedDate: "",
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  async created() {
    this.height = window.innerHeight - 208;
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.overlay = true;
    this.selectedDate = this.get_orgdate(
      this.$store.getters.get_org_details[0].organisation_created_on
    );

    this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    );
    this.isLoading = true;
    await this.Getshiftwork();
    await this.fetchdetails();
    await this.get_daily_late_report();
  },
  methods: {
    get_orgdate(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    fetchdetails() {
      this.GetWorkshiftitems.forEach((element) => {
        this.ShiftItems.push({
          workshift_name: element.workshift_name,
          workshift_id: element.workshift_id,
        });
      });
      // console.log(this.GetWorkshiftitems);
      this.shiftName = this.GetWorkshiftitems[0].workshift_id;
    },
    get_date(date) {
      let a = new Date(date.swipe_time * 1000).toLocaleTimeString("en-US", {
        timeZone:
          date.time_zone == null
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : date.time_zone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },

    async get_daily_late_report() {
      this.isLoadingMember = true;
      this.overlay = true;
      this.isLoading = true;

      try {
        this.lateItems = [];
        await API.graphql(
          graphqlOperation(LateArrivalsUsersList, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_time_stamp: new Date(this.date).getTime() / 1000,
              workshift_id: this.shiftName,
            },
          })
        ).then((res) => {
          this.lateItems = JSON.parse(res.data.LateArrivalsUsersList).items;
        });
        this.isLoadingMember = false;
        this.isLoading = false;
        // console.log(this.lateItems);
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.$store.commit("snackbar/showMessage", {
          content: err.errors[0].message,
          color: "red",
        });
        this.isLoadingMember = false;
        this.overlay = false;
        this.isLoading = false;
      }
    },
    callTimeConverter(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var hDisplay = h < 10 ? "0" + h + (h == 1 ? " : " : " : ") : h;
      var mDisplay = m < 10 ? "0" + m + (m == 1 ? "  " : "  ") : m;
      return hDisplay + mDisplay;
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>